<template>
  <div>
    <b-table
      striped
      hover
      :items="bannerDataList"
      :fields="fields"
      :busy="isLoading"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <!-- <template v-slot:cell(other)="{ item }">
        {{ item.other ? item.other.partner : "" }}
      </template> -->

      <template v-slot:cell(file)="{ item }">
        <img :src="item.file" class="image__table" alt="" />
      </template>

      <template v-slot:cell(redirect)="{ item }">
        <div class="text-capitalize">
          {{ item.other ? item.other.redirect_to : "" }}
        </div>
      </template>

      <template v-slot:cell(location)="{ item }">
        <div class="text-capitalize">{{ item.location.name || "-" }}</div>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>

          <b-dropdown-item @click="editItem(item)">
            <feather-icon icon="EditIcon" class="mr-75" />
            Update
          </b-dropdown-item>

          <b-dropdown-item @click="deleteItem(item.uuid)">
            <feather-icon icon="TrashIcon" class="mr-75" />
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-modal
      id="form-banner"
      centered
      :title="editIdModal != null ? 'Edit Banner' : 'Add Banner'"
      size="lg"
      no-close-on-backdrop
    >
      <ValidationObserver>
        <div class="form">
          <div class="form-group">
            <label>Title:</label>
            <validation-provider
              name="title"
              rules="required"
              v-slot="{ errors, classes }"
            >
              <input
                type="text"
                class="form-control"
                :class="classes"
                :state="errors.length > 0 ? false : null"
                placeholder="Banner Title"
                v-model="formPayloadBanner.title"
              />
              <small
                v-for="(validation, index) in validationsForm.title"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
          <div class="form-group row">
            <b-col cols="12" lg="6">
              <label for="startDate">Start Date:</label>
              <flat-pickr
                id="startDate"
                v-model="formPayloadBanner.start_date"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d' }"
              />
              <small
                v-for="(validation, index) in validationsForm.start_date"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </b-col>
            <b-col cols="12" lg="6">
              <label for="endDate">End Date:</label>
              <flat-pickr
                id="endDate"
                v-model="formPayloadBanner.end_date"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d' }"
              />
              <small
                v-for="(validation, index) in validationsForm.end_date"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </b-col>
          </div>
          <div class="form-group">
            <label for="">Image: (Required)</label><br />
            <p class="image__hint"><i>max: 2MB</i></p>
            <b-form-file
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @change="changePhoto($event)"
            />
            <small>Current photo:</small><br />
            <img
              v-if="photo_url_data !== null"
              :src="photo_url_data"
              style="max-width: 100%"
            />
            <small
              v-for="(validation, index) in validationsForm.file"
              :key="`errorName${index}`"
              class="text-danger"
              >{{ validation }}</small
            >
          </div>
          <div class="form-group">
            <label for="location">Location:</label>
            <validation-provider
              name="location"
              rules="required"
              v-slot="{ errors, classes }"
            >
              <v-select
                id="location"
                label="name"
                v-model="formPayload.selectedId"
                :options="bannerLocationList"
                placeholder="-- Pilih --"
                :class="classes"
                :state="errors.length > 0 ? false : null"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small
                v-for="(validation, index) in validationsForm.location"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
          <div class="form-group">
            <label for="redirect">Redirect To:</label>
            <validation-provider
              name="redirect"
              rules="required"
              v-slot="{ errors, classes }"
            >
              <v-select
                id="redirect"
                label="name"
                v-model="formPayloadBanner.other.redirect_to"
                :options="redirectList"
                :reduce="(redirectList) => redirectList.value"
                placeholder="-- Pilih --"
                :class="classes"
                :state="errors.length > 0 ? false : null"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small
                v-for="(validation, index) in validationsForm.location"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
          <div
            class="form-group"
            v-if="formPayloadBanner.other.redirect_to === 'feature'"
          >
            <label for="feature">Feature:</label>
            <validation-provider
              name="feature"
              rules="required"
              v-slot="{ errors, classes }"
            >
              <v-select
                id="feature"
                label="name"
                v-model="formPayloadBanner.feature_id"
                :options="featureListData"
                placeholder="-- Pilih --"
                :class="classes"
                :reduce="(featureListData) => featureListData.id"
                :state="errors.length > 0 ? false : null"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small
                v-for="(validation, index) in validationsForm.feature_id"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
          <div
            class="form-group"
            v-if="formPayloadBanner.other.redirect_to === 'url'"
          >
            <label>Url:</label>
            <validation-provider
              name="url"
              rules="required"
              v-slot="{ errors, classes }"
            >
              <input
                type="text"
                class="form-control"
                :class="classes"
                :state="errors.length > 0 ? false : null"
                placeholder="url"
                v-model="formPayloadBanner.other.url"
              />
              <small
                v-for="(validation, index) in validationsForm['other.url']"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
          <div class="form-group">
            <label>Partner:</label>
            <validation-provider
              name="partner"
              rules="required"
              v-slot="{ errors, classes }"
            >
              <input
                type="text"
                class="form-control"
                :class="classes"
                :state="errors.length > 0 ? false : null"
                placeholder="Partner"
                v-model="formPayloadBanner.other.partner"
              />
              <small
                v-for="(validation, index) in validationsForm['other.partner']"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
          <div class="form-group">
            <label>Text:</label>
            <validation-provider
              name="text"
              rules="required"
              v-slot="{ errors, classes }"
            >
              <input
                type="text"
                class="form-control"
                :class="classes"
                :state="errors.length > 0 ? false : null"
                placeholder="Text Inside Banner"
                v-model="formPayloadBanner.other.text"
              />
              <small
                v-for="(validation, index) in validationsForm['other.text']"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
          <div class="d-flex justify-content-end" v-if="isFreebies">
            <b-button
              size="sm"
              variant="primary"
              @click="addProduct"
              :disabled="formPayloadBanner.other.rewards.length >= 2"
            >
              Add Product
            </b-button>
          </div>
          <div
            v-if="isFreebies"
            v-for="(reward, index) in formPayloadBanner.other.rewards"
            :key="index"
            class="form-group row"
          >
            <b-col cols="12" lg="6">
              <label>Product ID:</label>
              <validation-provider
                name="product_id"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Product ID"
                  v-model="formPayloadBanner.other.rewards[index].product_id"
                />
                <small
                  v-for="(validation, idx) in validationsForm[
                    'other.rewards.product_id'
                  ]"
                  :key="`errorName${idx}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="5">
              <label>Provider:</label>
              <validation-provider
                name="provider"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <v-select
                  id="status"
                  label="name"
                  v-model="formPayloadBanner.other.rewards[index].provider"
                  :options="providers"
                  :reduce="(provider) => provider.value"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <small
                  v-for="(validation, idx) in validationsForm[
                    'other.rewards.provider'
                  ]"
                  :key="`errorName${idx}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="1" class="d-flex justify-content-end">
              <b-button
                size="sm"
                class="mt-2"
                variant="none"
                @click="removeProduct(index)"
              >
                <feather-icon icon="Trash2Icon" size="20" style="color: red" />
              </b-button>
            </b-col>
          </div>
        </div>
      </ValidationObserver>
      <template #modal-footer="{}">
        <b-button
          v-if="editIdModal == null"
          variant="primary"
          @click="createItem()"
          v-bind:disabled="isLoading"
        >
          Save Banner
        </b-button>
        <b-button
          v-else
          variant="primary"
          @click="updateItem()"
          v-bind:disabled="isLoading"
        >
          Edit Banner
        </b-button>
      </template>
    </b-modal>

    <div
      v-if="resultData.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPageList"
        :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BCol,
  BDropdown,
  BPagination,
  BFormFile,
  BDropdownItem,
  BButton,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BCol,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    bannerData: {
      type: "",
    },
    bannerLocation: {
      type: "",
    },
    featureList: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    photo_url: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    changePhoto: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    bannerData(value) {
      this.bannerDataList = value;
    },
    bannerLocation(value) {
      this.bannerLocationList = value;
    },
    featureList(value) {
      this.featureListData = value;
    },
    formPayload(value) {
      this.formPayloadBanner = value;
    },
    validations(value) {
      this.validationsForm = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
    editId(value) {
      this.editIdModal = value;
    },
    photo_url(value) {
      this.photo_url_data = value;
    },
    // filter: {
    //   handler(value) {
    //     this.$emit("filter", value);
    //   },
    //   deep: true,
    // },
  },
  data() {
    return {
      required,
      isLoadingComponent: true,
      currentPageList: 1,
      resultData: {},
      bannerDataList: [],
      bannerLocationList: [],
      featureListData: [],
      editIdModal: null,
      formPayloadBanner: {
        title: "",
        start_date: "",
        end_date: "",
        file: "",
        location: "",
        other: {
          partner: "",
          url: "",
          text: "",
          redirect_to: "",
          rewards: [
            {
              product_id: null,
              provider: null,
            },
          ],
        },
        selectedId: "",
      },
      photo_url_data: null,
      validationsForm: "",
      redirectList: [
        {
          name: "URL",
          value: "url",
        },
        {
          name: "Feature",
          value: "feature",
        },
      ],
      fields: [
        { key: "no" },
        { key: "title" },
        { key: "start_date" },
        { key: "end_date" },
        // { key: "other", label: "Partner" },
        { key: "redirect", label: "Redirect To" },
        { key: "location", label: "Location" },
        { key: "file", label: "Image" },
        { key: "actions" },
      ],
      providers: [
        { value: "xl", name: "XL" },
        { value: "axis", name: "Axis" },
      ],
    };
  },
  computed: {
    isFreebies() {
      return (
        this.formPayload.selectedId &&
        this.formPayload.selectedId.name === "Freebies Homepage"
      );
    },
    rows() {
      return this.bannerDataList.length;
    },
  },
  methods: {
    addProduct() {
      if (this.formPayloadBanner.other.rewards.length < 2) {
        this.formPayloadBanner.other.rewards.push({
          product_id: null,
          provider: null,
        });
      }
    },
    removeProduct(index) {
      if (index >= 0 && index < this.formPayloadBanner.other.rewards.length) {
        this.formPayloadBanner.other.rewards.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.image__table {
  width: 200px;
  height: 100px;
  object-fit: cover;
}
.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
</style>
