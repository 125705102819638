<template>
  <b-card title="Banner List">
    <b-row class="justify-content-between">
      <b-col cols="12" lg="4" class="d-flex align-items-center">
        <b-button
          @click="cleanUpForm()"
          v-b-modal.form-banner
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Create Banner
        </b-button>
      </b-col>
      <b-col
        cols="12"
        lg="4"
        class="d-flex align-items-center justify-content-end"
      >
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-2">
      <b-col cols="12" lg="4">
        <div class="form-group">
          <label for="location">Location:</label>
          <v-select
            id="location"
            label="name"
            placeholder="-- Pilih --"
            v-model="filter.locations"
            multiple
            :options="bannerLocation"
            :reduce="(option) => option.name"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="4">
        <div class="form-group mb-0">
          <label for="search">Search:</label>
          <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Search: Title"
            v-model="filter.search"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :bannerData="bannerData"
      :is-loading="isLoading"
      :get-data="getData"
      :delete-item="deleteItem"
      :formPayload="formPayload"
      :edit-item="editItem"
      :update-item="updateItem"
      :create-item="createItem"
      :change-photo="changePhoto"
      :current-page="currentPage"
      :editId="editId"
      :validations="validations"
      :photo_url="photo_url"
      :bannerLocation="bannerLocation"
      :featureList="featureList"
    />
  </b-card>
</template>

<script>
import Table from "@/components/banner/Table.vue";
import vSelect from "vue-select";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      bannerData: {},
      isLoadingExport: false,
      formPayload: Object,
      editId: null,
      filter: {},
      validations: "",
      photo_url: null,
      bannerLocation: [],
      featureList: [],
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  mounted() {
    this.getBannerLocation();
    this.getFeature();
  },
  created() {
    this.getData();
  },
  methods: {
    cleanUpForm() {
      this.editId = null;
      this.validations = "";
      this.formPayload = {
        title: "",
        start_date: "",
        end_date: "",
        file: "",
        location: "",
        other: {
          partner: "",
          url: "#",
          redirect_to: "",
          text: "",
          rewards: [
            {
              product_id: null,
              provider: null,
            },
          ],
        },
        feature_id: "",
        selectedId: "",
      };
      this.photo_url = null;
    },
    editItem(item) {
      this.cleanUpForm();
      this.editId = item.uuid;
      this.formPayload = {
        title: item.title,
        start_date: item.start_date,
        end_date: item.end_date,
        selectedId: item.location,
        file: item.file,
        other: {
          partner: item.other.partner,
          url: item.other.url || "#",
          text: item.other.text || "",
          redirect_to: item.other.redirect_to,
          reward: {
            product_id: item.other.reward?.product_id || null,
          },
          rewards: item.other?.rewards || [],
        },
        feature_id: item.feature !== null ? item.feature.id : "",
      };
      (this.photo_url = item.file), this.$bvModal.show("form-banner");
    },
    changePhoto(event) {
      this.formPayload.file = event.target.files[0];
      this.showPhoto(event.target.files[0]);
    },
    showPhoto(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.photo_url = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    getBannerLocation() {
      this.$http.get("/api/v1/admin/banners/location").then((response) => {
        this.bannerLocation = response.data.data;
      });
    },
    getFeature() {
      this.$http.get("/api/v1/admin/feature-visibility").then((response) => {
        this.featureList = response.data.data;
      });
    },
    preparePayload() {
      const form = new FormData();

      if (this.formPayload.selectedId != null) {
        this.formPayload.location = this.formPayload.selectedId.id;
      }

      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key)) {
          if (key === "other") {
            form.append("other[partner]", this.formPayload.other.partner || "");
            form.append("other[url]", this.formPayload.other.url || "");
            form.append("other[text]", this.formPayload.other.text || "");
            form.append(
              "other[redirect_to]",
              this.formPayload.other.redirect_to || ""
            );

            if (this.formPayload.selectedId && this.formPayload.selectedId.name === "Freebies Homepage") {
              if (Array.isArray(this.formPayload.other.rewards)) {
                this.formPayload.other.rewards.forEach((reward, index) => {
                  form.append(
                    `other[rewards][${index}][product_id]`,
                    reward.product_id || ""
                  );
                  form.append(
                    `other[rewards][${index}][provider]`,
                    reward.provider || ""
                  );
                });
              }
            }

            if (this.formPayload.other.reward) {
              form.append(
                "other[reward][product_id]",
                this.formPayload.other.reward.product_id || ""
              );
            }
          } else if (key !== "selectedId") {
            form.append(key, this.formPayload[key] || "");
          }
        }
      }

      return form;
    },
    createItem() {
      var form = this.preparePayload();
      this.isLoading = true;
      this.$http
        .post("/api/v1/admin/banners", form)
        .then((response) => {
          this.$bvModal.hide("form-banner");
          this.getData(this.currentPage);
          successNotification(this, "Success", "Banner successfully created");
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
    updateItem() {
      var form = this.preparePayload();
      this.isLoading = true;
      this.$http
        .post(`/api/v1/admin/banners/${this.editId}`, form)
        .then((response) => {
          this.$bvModal.hide("form-banner");
          this.getData();
          successNotification(this, "Success", "Banner successfully updated!");
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$http
        .get("/api/v1/admin/banners", {
          params: queryParams,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.bannerData = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(slug) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Banner?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/api/v1/admin/banners/${slug}`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Banner successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
